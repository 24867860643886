import * as React from "react";

import { PropertyTypesCarousel as PropertyTypesCarouselSharedUI } from "@shared-ui/retail-storefront-property-types-carousel";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

export const PropertyTypesCarousel: React.FC<ComponentProps> = (props) => {
  const { templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  return <PropertyTypesCarouselSharedUI />;
};

export default PropertyTypesCarousel;
